<template lang="">
    <div>
        <Support/>
    </div>
</template>
<script>
export default {
    components:{
        Support: () =>import('@/components/support/Support.vue')
    }
}
</script>
<style lang="">
    
</style>